footer {
    .footer-contact {
        display: flex;
        justify-content: center;
        position: relative;
        isolation: isolate;
        padding: 100px 0 200px 0;

        @include respond($bp-768px) {
            padding: 60px 0;
        }

        overflow-x: clip;

        .backgroundImage {
            position: absolute;
            inset: 0;
            z-index: -1;
            filter: brightness(.2);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .full-row {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @include respond($bp-768px) {
                grid-template-columns: 1fr;
                gap: 20px;
            }

            .contact-text {
                line-height: initial;

                .contactTitle,
                p {
                    margin: 0;
                    color: $white;
                }

                .contactTitle {
                    font-size: 20px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 34px;
                    font-weight: bolder;
                }

                .contactLinks {
                    display: grid;
                    gap: 20px;
                    grid-auto-columns: max-content;
                    padding: 0;
                    list-style: none;
                    margin: 0;
                    margin-top: 40px;

                    li {
                        @include button($blue, $white);
                        margin: 0;
                        overflow: hidden;
                        border-radius: 50px;
                        border: 1px solid;
                        border-color: $blue;
                        position: relative;
                        isolation: isolate;

                        a {
                            font-weight: bold;
                            display: block;
                            color: $white;
                            padding: 10px 20px;
                            width: 100%;
                            text-align: center;
                            z-index: 1;
                        }

                        &:last-child {
                            @include button(none, $white);
                            border: 1px solid #fff;
                            backdrop-filter: saturate(1.80) blur(20px);

                            a {
                                color: $white;
                            }

                            a:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }

            .form.contact {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                align-items: center;

                .field {
                    position: relative;

                    label {
                        color: $white;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    &:is(.name, .phone) {
                        grid-column: 1;
                    }

                    &.message {
                        grid-column: 1/3;

                        textarea {
                            resize: none;
                        }
                    }

                    &.required label::after {
                        content: "*";
                    }

                    div.mage-error {
                        color: $white;
                        position: absolute;
                    }
                }

                legend {
                    color: $white;
                }

                .field-recaptcha {
                    position: absolute
                }

                .submit {
                    @include button($blue, $white);
                    padding: 10px 20px;
                    width: max-content;
                    justify-self: flex-end;

                    @include respond($bp-768px) {
                        margin-left: auto;
                        width: auto;
                    }

                    span {
                        display: block;
                        color: $white;
                        width: 100%;
                        text-align: center;
                        font-family: $raleway;
                    }
                }
            }
        }

        .decoration-container {
            position: absolute;
            bottom: 0;

            .blackpath {
                fill: transparent;
            }

            .bluepath {
                color: $blue;
            }

            .redpath {
                color: $red;
            }

            .whitepath {
                color: $black;
            }
        }
    }

    .footer-bottom {
        background-color: $black;
        padding-top: 60px;
        padding-bottom: 60px;

        .full-row {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
            gap: 40px;

            >* {
                color: $white;

                .title {
                    font-size: 16px;
                    font-weight: 800;
                    margin: 0;
                }

                .title+p {
                    margin-top: 20px;
                }

                ul,
                li {
                    margin: 0;
                    padding: 0;
                    font-size: 1.6rem;
                }

                a {
                    color: $white;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 2px;
                        bottom: -2px;
                        background-color: $blue;
                        transition: ease-out .3s;
                        left: 50%;
                        translate: -50% 0;
                    }

                    &:hover {
                        color: $blue;
                    }
                }

                ul {
                    margin-top: 20px;
                    list-style: none;

                    li {
                        p {
                            display: flex;

                            strong {
                                margin-right: 5px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

            >*[class*="col"] {
                ul+.title {
                    margin-top: 40px;
                }

                ul {
                    margin-top: 5px;
                }
            }

            .copyrights-column {
                @media(width >=500px) {
                    grid-column: span 2;
                }

                margin-top: 10px;
                font-size: 1.6rem;
                margin-bottom: 0;
            }

            .newsletter-column {
                .newsletter-form {
                    display: grid;
                    gap: 20px;
                    margin-top: 15px;

                    .email {
                        border: 1px solid $white;
                        border-radius: 50px;
                        background: transparent;
                        color: $white;
                    }

                    .submit {
                        @include button($blue, $white);
                        width: max-content;

                        span {
                            display: block;
                            color: $white;
                            width: 100%;
                            text-align: center;
                            z-index: 1;
                            font-family: $raleway;
                        }
                    }
                }
            }
        }
    }
}