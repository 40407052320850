@media only screen and (max-width: 767px) {
	#html-body #page210 .amsearch-wrapper-block {        
        grid-row: 2;
        grid-column: span 4;

        // position: absolute;
        max-width: 100%;

        .amsearch-form-block {
            display: block;
        }

        .amsearch-result-section {
            grid-template-columns: 1fr;
            
            .amsearch-product-list {
                grid-template-columns: 1fr;
                justify-items: unset;
                gap: 0;
            }
        }
        .amsearch-result-section section[data-amsearch-analytics=block] {
            grid-column: span 2;
            grid-row: 2;
        }
	}
}

@media only screen and (min-width: 768px) {

    .amsearch-button.-icon.-loupe.-loupe-trigger.-clear {
        display: none;
    }
    .amsearch-result-section section[data-amsearch-analytics=block] {
        max-width: 90%;        
    }
    
    .amsearch-items-section {
        min-width: 150px;
    }
}

#page210>header {
    top: 0;
    width: 100%;
    background-color: $black;
    z-index: 2;
    position: relative;

    .cms-intro-row {
        display: none;
    }

    .amsearch-result-section {
        max-width: 1200px;
        width: auto;
    }
    
    .amsearch-block-header .amsearch-title
    .amsearch-item.product-item {
        word-break: none;
    }

    .amsearch-input-wrapper {
        width: 100%;
        
        input[type="text"] {
            border-radius: 50px;
            background: $black;
            border: 1px solid $greyLightest;
            height: 40px;
            color: $greyLightest;
        }
    }

    .amsearch-link, .amsearch-highlight {
        color: $blue;

        &:hover, &:focus, &:focus-visible, &:active {
            color: $red;
        }
    }

    .amsearch-form-block {
        justify-content: center !important;

        &.-opened {
            max-width: 400px;
        }
    }

    .amsearch-result-section {
        display: grid;
        grid-template-columns: 1fr 1fr;         
        grid-column: 1;
        grid-row: 1;
        padding: 2em;

        section[data-amsearch-analytics="block"] {
            grid-column: 2;
            grid-row: 1;   
            border: 0 !important;
        }

        .amsearch-product-list {
            display: grid !important;
            grid-template-columns: repeat(3, minmax(300px, 1fr));
            gap: 1em;
            justify-items: center;

            li {
                list-style-type: none;
                max-width: 80% !important;

                &:hover {
                    background: $white;
                }
            }
        }
        .amsearch-message-block {
            grid-row: 3;
        }
    }

    &.sticky {
        position: fixed;
    }

    @include respond($bp-768px) {}

    .header-top{
        background-color: $blue;
        .full-row{
            padding-top: 10px;
            padding-bottom: 10px;
            p{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin: 0;
                a{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .fa-phone,.fa-envelope{
                        display: block;
                        width: 14px;
                        aspect-ratio: 1;
                        &:not(svg){
                            font-size: 0;
                        }
                    }
                    color: white;
                    &:hover{
                        color: black;
                    }
                }
            }
        }
    }

    > .full-row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
        padding-top: 20px;
        padding-bottom: 20px;

        @include respond($bp-1024px) {
            grid-template-columns: repeat(4, auto);
        }

        @include respond($bp-768px) {
            grid-template-columns: repeat(4, auto);
            gap: 10px;
        }

        .nav-toggle {
            position: static;
        }

        .logo {
            margin: 0;
            max-width: none;
        }

        .block.block-search {
            all: unset;
            border: 1px solid $white;
            border-radius: 50px;
            padding: 2px 15px;
            font-size: 14px;
            font-family: $raleway;

            @include respond($bp-768px) {
                border: none;
                padding: 0;
            }

            .minisearch {
                display: flex;
                align-items: center;

                .field.search {
                    width: 100%;
                }

                .actions {
                    margin-left: auto;

                    button {
                        all: unset;

                        @include respond($bp-768px) {
                            display: none;
                        }

                        svg {
                            color: $white;
                            width: 11px;
                            height: auto;
                            aspect-ratio: 1;
                        }

                        &::before {
                            all: unset;
                        }
                    }
                }
            }

            #search {
                background-color: transparent;
                border: none;
                color: $white;
                font-size: 14px;
                font-family: $raleway;

                &::placeholder {
                    text-transform: lowercase;
                }
            }

            .nested {
                display: none;
            }

            .control {
                @include respond($bp-768px) {
                    position: absolute;
                    background-color: $black;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    top: calc(100% - 30px);
                    box-sizing: border-box;
                }

                top: 100%;
                padding: 0;
                border-top: none;
            }
        }

        @import "menu";

        .header-links {
            margin-left: auto;
            grid-column: -2;
            display: flex;
            align-items: center;
            gap: 10px;

            .website-link {
                @include button($blue, $white);

                p {
                    margin-bottom: 0;
                }

                a {
                    display: block;
                    padding: 9px 18px;
                    color: inherit;
                    font-weight: bold;
                    font-size: 14px;
                }
            }

            .minicart-wrapper {
                line-height: 0;
                float: none;
                isolation: isolate;
                margin: 0;

                .price-wrapper {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: flex-end;
                    justify-content: flex-end;
                    gap: 5px;
                }

                .action.showcart {
                    margin-bottom: 0;

                    &::before {
                        all: unset;
                    }

                    .animationwrapper {
                        @include button($red, $white);
                        padding: 12px;
                        padding-left: 13px;
                        width: 38px;
                        aspect-ratio: 1;
                        border-radius: 100%;
                    }
                }

                .counter.qty {
                    position: absolute;
                    left: 100%;
                    bottom: 100%;
                    translate: -60% 60%;
                    aspect-ratio: 1;
                    background-color: $blue;
                    border-radius: 100%;
                    z-index: 1;
                }
            }

            .block-minicart {
                line-height: 1;

                .minicart-items-wrapper {
                    max-height: 400px;
                }

                .top-cart-btn-checkout {
                    margin-top: 20px;
                }

                .items-total {
                    margin: 0 0 30px 10px;
                }

                .subtotal {
                    float: right;
                    text-align: initial;
                    display: flex;
                    gap: 5px;

                    .price-container {
                        .price-wrapper {
                            .price {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .price-on-request {
                    font-size: 14px;
                }

                span.price {
                    color: $black;
                    font-size: normal !important;
                    font-weight: 400 !important;
                }

                .product-item-pricing .price-wrapper {
                    display: grid;
                    justify-content: initial;

                    .price-including-tax,
                    .price-excluding-tax {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: flex-end;
                        justify-content: flex-end;
                        gap: 5px;
                    }
                }
            }
        }
    }
}

@include respond($bp-768px) {
    .nav-before-open body {
        overflow: unset;
    }

    html:is(.nav-open, .nav-before-open) {
        overflow: hidden;

        #page210>header .full-row {
            .nav-toggle:after {
                display: none
            }

            @import "responsive-menu";
        }
    }
}