@import "../base/variables";

*,
*::after,
*::before {
	/* inherit from html and apply everywhere  */
	box-sizing: inherit;
}

html {
	/* borders and paddings removed from width and height  */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

b,
strong {
	font-weight: 700;
}

em,
i {
	font-style: italic;
}

mark {
	background: $color-secondary;
	color: $color-primary;
}

hr {
	border: 0;
	border-top: 1px solid $color-secondary;
	margin-bottom: 20px;
	margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
	line-height: 1.1;
}

h1 {
	font-size: 30px;
	margin: 20px 0;
}

h2 {
	font-size: 26px;
	margin: 20px 0;
}

h3 {
	font-size: 22px;
	margin: 20px 0;
}

h4 {
	font-size: 18px;
	margin: 10px 0;
}

h5 {
	font-size: 14px;
	margin: 10px 0;
}

h6 {
	font-size: 12px;
	margin: 10px 0;
}

a,
a:link,
a:visited,
a:focus,
a:active {
	color: $blue;
	text-decoration: none;
	@include transition;

	&:hover {
		color: $black;
		text-decoration: none;
	}
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 20px;
}

ul>li,
ol>li {
	margin-top: 0;
	margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
	margin-bottom: 0;
}

dl {
	margin-bottom: 20px;
	margin-top: 0;
}

dt {
	font-weight: 700;
	margin-bottom: 5px;
	margin-top: 0;
}

dd {
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 0;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
	background: $color-white;
	background-clip: padding-box;
	border: 1px solid $color-secondary;
	border-radius: 0;
	font-family: $font-primary;
	font-size: 15px;
	height: 32px;
	line-height: 1.4;
	padding: 0 9px;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

select {
	background: $color-white no-repeat center right /8px 4px;
	background-clip: padding-box;
	border: 1px solid $color-secondary;
	border-radius: 0;
	font-family: $font-primary;
	font-size: 15px;
	height: 32px;
	line-height: 1.4;
	padding: 5px 10px 4px;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;

	@include transition;

	&:hover {
		border-bottom-color: $color-primary;
	}

	&::-ms-expand {
		display: none;
	}
}

textarea {
	background: $color-white;
	background-clip: padding-box;
	border: 1px solid $color-secondary;
	border-radius: 0;
	font-family: $font-primary;
	font-size: 15px;
	height: auto;
	line-height: 1.4;
	margin: 0;
	padding: 10px;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	resize: vertical;
}

input,
textarea,
select {
	&:focus {
		border-color: $color-primary;
	}
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

button:active,
.cart.table-wrapper .actions-toolbar>.action:active,
.cart.table-wrapper .action-gift:active {
	-webkit-box-shadow: none;
	box-shadow: none;
}

// button,
// a.action.primary,
// .cart.table-wrapper .actions-toolbar > .action,
// .cart.table-wrapper .action-gift {
// 	border-radius: 0;
// }
.action.primary {
	@include btn-primary;
}

div.mage-error[generated] {
	margin-top: 3px;
}

.abs-margin-for-forms-desktop,
form .fieldset .legend,
.column:not(.sidebar-main) form div.actions-toolbar,
.column:not(.sidebar-additional) form div.actions-toolbar,
.login-container form .fieldset:after {
	margin-left: 0;
}

form .fieldset>.legend {
	display: block;
	width: 100%;
	font-size: 18px;
	border-bottom: 1px solid #333232;
	font-weight: 500;
	margin-bottom: 15px;
	padding-bottom: 12px;
}

form .fieldset>.field:not(.choice)>.label,
form .fieldset>.field>.label {
	width: 100%;
	padding: 0;
	text-align: left;
	margin-left: 0;
	margin-bottom: 5px;
}

form .fieldset>.field>.control,
form .fieldset>.field:not(.choice)>.control {
	width: 100%;
}

form .fieldset>.field.choice:before,
form .fieldset>.field.no-label:before {
	padding: 0;
	width: auto;
}

.page-print .sections.nav-sections {
	display: none;
}

iframe {
	max-width: 100%;
}

:root {
	--background-color: #{$greyLightest};
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	color: $color-black;
	font-family: $font-primary;

	#page210 {
		background-color: var(--background-color);
		flex: 1;
	}

	.full-row {
		@include center-container;
	}
}

.page.messages,
.checkout-container .messages {
	top: 13rem;
	height: max-content;
}

.banner-decoration {
	display: block;
	height: auto;
	width: 111%;
	transform: translate(-5%, 1px);
}
.product .imagewrapper img#placeholder-image {
	object-fit: contain !important;
	filter: opacity(0.5);
}