.phpro-cookie-notice {
    background-color: rgba($black, .8);

    .notice__description {
        color: $white;
    }

    .notice__actions {
        display: flex;
        gap: 20px;

        .action {
            @include button($blue, $white);
            margin-top: 0;
        }
    }

}

.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show {
    .modal-inner-wrap {
        top: 45%;
        transform: translateY(-55%);

        .phpro-cookie-consent-modal {
            background-color: $greyLightest;

            .consent-tabs-content {
                background-color: $greyLightest;

                .cookie-toggle-wrapper .cookie-toggle input:checked+.cookie-toggle-slider {
                    background-color: $blue;
                }

                p.title {
                    font-size: 30px;
                }
            }
        }

        .action {
            @include button($blue, $white);

            &:first-child {
                margin-right: 20px;
            }
        }

        .consent-tabs {

            .consent-tab {
                @include transition;
                font-size: 16px;
                background-color: transparent;
                border: 0;

                &.active {
                    background-color: transparent;
                }

                &:hover,
                &.active {
                    color: $blue;
                }
            }
        }
    }
}