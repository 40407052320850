nav.navigation{
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 0;
    background: $black;
    isolation: isolate;
    overflow: auto;
    .parent .level-top:after{
        position: static;
    }
    > ul {
        display: grid;
        li.level0 {
            display: grid;
            align-items: center;
            padding: 0 20px;
            min-height: 60px;
            > a{
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .ui-icon{
                    display: none;
                }
            }
            > .submenu{
                grid-template-columns: 1fr;
                background: none;
                width: 100%;
                .ui-menu-item a{
                    padding-left: 0;
                }
                li.level1 {
                    &.active{
                        a span{
                            color: $blue;
                        }
                    }
                    a {
                        &:hover{
                            background: inherit;
                        }
                        span{
                            color: $white;
                        }
                    }
                    > .submenu{
                        display: block !important;
                        position: static;
                        background-color: inherit;
                        padding-left: 10px;
                        li.level2{
                            a span {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
.block.block-search{
    z-index: 99;
    .form.minisearch{
        &.active{
            .control{
                margin-top: 30px;
                border: 1px solid white
            }
        }
    }
}