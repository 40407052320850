#minicart-content-wrapper{
    .actions:has(.secondary){
        display: none;
    }
}

#top-cart-btn-checkout{
    @include button($blue, $white);
    padding: 10px;
    color: white;
}

#top-cart-btn-checkout:hover{
    color: $blue;
    border: 1px solid $blue;
}

fieldset#customer-email-fieldset .field .control .note{
    display: none !important;
}

section.cart-row .full-row .cart-container .cart.actions a::before, section.cart-row .full-row .cart-container .cart.actions button::before{
    background-color: #ffffff00;
}

@media(width <= 768px){
    #shopping-cart-table{
        td{
            display: table-cell;
            float: none;
        }
    }
}