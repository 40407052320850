/*
==================================
=== MEDIA QUERY 1440px and below ===
==================================
*/
@media only screen and (max-width: 1440px) {

}

/*
==================================
=== MEDIA QUERY 1024px and below ===
==================================
*/
@media only screen and (max-width: 1024px) {
	
}

/*
==================================
=== MEDIA QUERY 779px and below ===
==================================
*/
@media only screen and (max-width: 767px) {
	.checkout-index-index .nav-toggle {
		display: block;
	}
}

/*
==================================
=== MEDIA QUERY 640px and below ===
==================================
*/
@media only screen and (max-width: 640px) {
	
}

/*
==================================
=== MEDIA QUERY 480px and below ===
==================================
*/
@media only screen and (max-width: 480px) {
	
}