nav.navigation{
    position: static;
    @include respond($bp-768px){
        display: none;
    }
    
    .level0 .submenu .active > a{
        border-color: $blue;
    }

    .level0 .submenu a:hover{
        background-color: $white;
        .span:hover{
            color: $blue; 
        }
    }

    all: unset;
    ul{
        background-color: $black;
        padding: 0;
        .submenu{
            background-color: $white
        }
        li{
            position: static;
            a{
                span{
                    color: $black;
                    font-weight: bold;
                    font-size: 14px;
                    &:hover{
                        color: $blue;
                    }
                }
            }
        }
    }
    > ul{
        z-index: 10;
        border: none;
        position: static;
        li{
            @media(width >= 1440px){
                position: relative;
            }
            .submenu{
                min-width: 0;
            }
            &.level0{
                >a {
                    color: $white;
                    display: flex !important;
                    flex-direction: row-reverse;
                    justify-content: start;
                    border: none !important;
                    padding: 0 !important;
                    span{
                        color: $white;
                        &:hover{
                            color: $blue;
                        }
                        &.ui-menu-icon{
                            position: static !important;
                            transition: .3s;
                            rotate: z 360deg;
                            &::after{
                                font-size: 35px !important;
                                font-weight: bold !important;
                            }
                        }
                    }
                }
                &:hover{
                    >a span.ui-menu-icon{
                        rotate: z 270deg;
                    }
                }
                >.submenu[aria-expanded=true]{
                    grid-template-columns: repeat(5, 210px);
                    border: none;
                    left: 0 !important;
                    
                    @media(width > 768px){
                        display: grid !important;
                        top: calc(100% - 20px) !important;
                    }
                    @media(width < 1440px){
                        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
                        width: 100%;
                    }
                }
            }
            &.level1{
                display: grid;
                grid-template-rows: max-content 0fr;
                transition: ease-in .3s;
                height: max-content;
                position: relative;
                &:hover{
                    grid-template-rows: max-content 1fr;
                    transition: ease-out .3s;
                    >a span.ui-menu-icon{
                        rotate: z 0deg;
                    }
                }
                &.active{
                    >a{
                        border: none;
                        span{
                            color: $blue;
                        }
                    }
                }
                >a{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: start;
                    span{
                        color: $black;
                        &:hover{
                            color: $blue;
                        }
                        &.ui-menu-icon{
                            position: static !important;
                            transition: .3s;
                            rotate: z 90deg;
                            width: max-content;
                            height: max-content;
                            &::after{
                                font-size: 35px !important;
                                font-weight: bold !important;
                            }
                        }
                    }
                }
                &:hover >.submenu {
                    display: block !important;
                }
                
                >.submenu{
                    display: none !important;
                    position: absolute;
                    border: none;
                    box-shadow: none;
                    overflow: hidden;
                    min-height: 0;
                    background-color: $greyLighter;
                    left: 0 !important;
                    @media(width > 768px){
                        top: 100% !important;
                    }
                    right: auto;
                    height: min-content;

                    li a {
                        padding: 3px 20px;
                        span{
                            font-weight: normal;
                        }
                    };
                }
                &:nth-child(n+2):nth-child(-n+5)::before{
                    content: "";
                    height: 100%;
                    width: 0px;
                    background: $greyLight;
                    display: block;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}